<template>
  <div>
    <v-card flat color="transparent">
      <v-card-title class="ml-0">
        <v-btn
          icon
          tile
          outlined
          text
          large
          class="mt-n2 ml-n4 rounded-lg"
          @click="$router.go(-1)"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <h1 class="ml-4 text-title">
          Order {{$route.params.id}}
        </h1>

        <v-btn icon tile outlined text class="mt-n2 ml-4 rounded-lg" large>
          <v-icon>
            mdi-file-multiple
          </v-icon>
        </v-btn>
      </v-card-title>
    </v-card>

  <div v-if="getOrderDetail">
      <v-row class="order-snip mt-3">
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="">
              {{ getOrderDetail.topic === null ? '( no topic )': getOrderDetail.topic }}
            </v-card-title>
            <v-card-text class="mt-n4 pt-0">
              <span> {{ getOrderDetail.language }} </span>
              <v-btn
                class="ml-4"
                :color="orderStatusColor(getOrderDetail.order_status)"
                x-small
                @click="routerPass(getOrderDetail)"
              >
                {{ getOrderDetail.order_status }}
              </v-btn>
              <v-btn icon tile v-if="getOrderDetail.order_status === 'closed'">
                <v-icon small>
                  mdi-lock-outline
                </v-icon>
              </v-btn>
              <span class="ml-4">
                {{ formatDateMonth(getOrderDetail.final_date) }}
              </span>
              <span class="ml-4" :class="{'red-text': timeErrorStatus(getOrderDetail.final_date)}"> {{ formatOrderDate(getOrderDetail.final_date)}} </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2" class="view mt-0">
          <v-hover class="vertical ma-0">
              <div class="pa-3" @click="showOrderModal = true">
                <v-icon x-large>mdi-chevron-right</v-icon>
                <div>
                  View Details
                </div>
              </div>
          </v-hover>
        </v-col>
      </v-row>
      <v-row class="mt-5" v-if="getOrderDetail.order_status === 'bidding' || getOrderDetail.order_status === 'open'">
        <v-col>
          <PlaceBid :order='getOrderDetail' @activateChat="getOrders"/>
        </v-col>

        <v-col>
          <ChatStarter :room_id="roomId" class="mb-3" v-if="roomId" />
          <customerRating :order="getOrderDetail"/>
        </v-col>
      </v-row>
      <v-row v-if="getOrderDetail.order_status !== 'bidding' && getOrderDetail.order_status !== 'open' ">
        <v-col>
          <customerLocal :order="getOrderDetail" />
        </v-col>
        <v-col>
          <ChatStarter :room_id="roomId" v-if="roomId" />
        </v-col>
      </v-row>
      <v-card :color="ant" class="mt-5 mb-5" v-if="getOrderDetail.order_status === 'bidding' || getOrderDetail.order_status === 'open'"> 
        <v-card-text class="">
          <v-row>
            <v-col>
              <v-simple-table class="transparent">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Assignment Type:</td>
                      <td>{{ getOrderDetail.assignment_type }}</td>
                    </tr>
                    <tr class="">
                      <td>Service:</td>
                      <td>{{ getOrderDetail.services }}</td>
                    </tr>
                    <tr>
                      <td>Sources:</td>
                      <td>{{ !getOrderDetail.sources ? 'N/A': getOrderDetail.sources }}</td>
                    </tr>
                    <tr>
                      <td>Citation Style:</td>
                      <td>{{ !getOrderDetail.citation ? 'N/A' : getOrderDetail.citation }}</td>
                    </tr>
                    <tr>
                      <td>Language:</td>
                      <td>{{ getOrderDetail.language }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table class="transparent">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Pages/Words:</td>
                      <td>{{!getOrderDetail.pages ? 'N/A' : getOrderDetail.pages }}</td>
                    </tr>
                    <tr class="">
                      <td>Education level:</td>
                      <td>{{ !getOrderDetail.level ? 'N/A' : getOrderDetail.level }}</td>
                    </tr>
                    <tr>
                      <td>Subject:</td>
                      <td>{{ !getOrderDetail.subject ? 'N/A' : getOrderDetail.subject }}</td>
                    </tr>
                    <tr>
                      <td>Your Dealine:</td>
                      <td>{{ !getOrderDetail.final_date ? 'N/A' : formatOrderDate(getOrderDetail.final_date) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-divider class="mt-5 mb-3"> </v-divider>
          <span class="ml-3">Attached files: {{ getOrderDetail.file.length}} </span>
          <v-divider class="mt-3"> </v-divider>
        </v-card-text>
        <v-card-text class="mb-5">
          <h1 class="text-h5 ml-3">Description</h1>
          <div class="ml-3 mb-5">
            <v-clamp autoresize :max-lines="1.5">{{ !getOrderDetail.instruction ? 'N/A' : getOrderDetail.instruction }}</v-clamp
            ><a @click="showOrderModal = true"> read more </a>
          </div>
        </v-card-text>
        <v-card-actions class="mt-5"></v-card-actions>
      </v-card>
      <ReviewComponent :orderDetail="getOrderDetail" class="mt-3" v-if="getOrderDetail.rating" />
      <contract :order="getOrderDetail"  v-if="getOrderDetail.order_status !== 'bidding' && getOrderDetail.order_status !== 'open' " />
      <filesUpload class="mt-5 mb-7" v-if="getOrderDetail.order_status !== 'bidding' && getOrderDetail.order_status !== 'open' " />
    </div>
    <OrderViewModal :show="showOrderModal" @close="showOrderModal = false" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import timezoneMxn from '@/mixins/timezone_mixin';
import VClamp from "vue-clamp";

export default {
  name: "orderDetail",
  components: {
    VClamp,
    ChatStarter: () => import("./_components/chatStarter"),
    customerRating: () => import("./_components/customerRating"),
    PlaceBid: () => import("./_components/placeBid"),
    contract: () => import("./_components/contract"),
    customerLocal: () => import("./_components/customerLocal"),
    filesUpload: () => import("@/components/filesUpload"),
    ReviewComponent: () => import('./_components/reviewComponent'),
    OrderViewModal: () => import("@/modules/MyOrders/MyBids/orderViewModal"),
  },
  mixins: [colorMxn, validationMxn, timezoneMxn],
  data() {
    return {
      showOrderModal: false,
      html: "...read more",
      order: {
        assign_type: "Math Assignment",
        services: "Writing",
        pages: "1 Page",
        level: "COllege",
        language: "English",
        final_date: "Aug 11, 2020, 01:58 PM ",
        assign_topic: "Machine learning",
        subject: "Data Science",
        sources: "3 Sources Required",
        citation: "APA 6th edition",
        order_status: 2,
        first_chat:
          "Hello. I have read all the instructions carefully and I am confident that I can deliver quality work in good time.",
        files: [
          {
            name: "Profile Picture",
            size: "5.4 KB",
            time: "Jan 11, 01:10 AM"
          }
        ],
        instructions:
          "Researchers suggest that many angioplasties and implanted cardiac pacemaker surgeries done in the United States each year are medically unwarranted. The additional risk to patients’ health posed by these surgeries and the high cost of health care that result are an obvious concern to those who are trying to balance the risks and benefits in health care decisions. However, the financial stakes are very high because Boston Scientific Corp. and Johnson & Johnson, and other companies that make pacemakers and the stents for angioplasties earn significant profits on",
        customer_profile: {
          customer_slug: "268254",
          countryCode: "ke",
          local_time: "22:30pm",
          orders_posted: "13",
          acceptance_rate: 91,
          payrate: 91
        }
      }
    };
  },
  computed: {
    ...mapGetters(['getOrderDetail', 'getSession', 'getReload']),
    roomId() {
     const bid = this.getOrderDetail ? this.getOrderDetail.bids.filter((el) => el.writer_id === this.getSession.writer.id): null;
     const result = bid.length > 0 ? bid[0].room_id : null;
     return result;
    },
  },
  watch: {
    getReload(val) {
      if (val) {
        this.getOrders();
      }
    }
  },
  mounted() {
    this.getOrders();
    this.getOrderDetail
  },
  methods: {
    ...mapMutations(['setMountUploadedFile', 'setOrderDetail', 'setReload']),
    async getOrders() {
      const payload = {
        page: 1,
      };
      const fullPayload = {
        params: payload,
        endpoint: `/order-detail/${this.$route.params.id}`
      };
      const response = await this.performGetActions(fullPayload);
      this.setMountUploadedFile(response.data[0].file);
      this.setOrderDetail(response.data[0]);
      this.setReload(false);
    },
  }
};
</script>

<style scoped>
.order-snip {
  background-color: #273142;
  height: 120px;
  margin-left: 2px;
  border-radius: 10px;
}
.transparent {
  background-color: transparent;
}

.vertical {
  border-left: 1px solid rgb(95, 93, 93);
}

.view :hover {
  transition: opacity .4s ease-in-out;
  background: #737984;
}
</style>
